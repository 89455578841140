import React from 'react';
import { Helmet } from "react-helmet";
// Start Importing components for this page
import PageBanner from './components/PageBanner';
import PageNavigation from './components/PageNavigation';

const HomePage = () => {

  return (
    <React.Fragment>
      <header className="main-header">
        <PageNavigation />
        <PageBanner isHome={ true } caption='Hem'/>
      </header>

      <main className="home-content">
        <Helmet>
          <title>Rörmossen | Hem</title>
          <meta name="description" content="Rörmossens humlegård med lokalodlat humle för ölbryggning med mera. Startsida med kort information om vilka vi är" />
          <meta name="keywords" content="Rörmossen,Humle,Lokalt,Öl,Bryggerier,Start,Hem,Sverige,Information" />
          <link rel="canonical" href="https://www.rormossenshumle.se/hem" />
        </Helmet>

        <div className="home-content__textbox">
          <h1>Välkommen!</h1>

          <p>På Rörmossens Humlegård odlas sedan 2016 svensk humle av klonen SWE 4, Hulla Norrgård.<br/>
          Det är en klon som fanns redan på 1600-talet som samlats in och bevaras i den svenska nationella genbanken vid SLU,
          Sveriges lantbruksuniversitet, i Alnarp. Den ingår i Grönt Kulturarv.</p>

          <p>Odlingen finns på en släktgård strax utanför Kungälv. Vi som odlar heter Agneta och Christian Leemann.
            Till vardags arbetar vi med annat och odlingen är något vi håller på med på vår fritid.</p>
        </div>

        <div className="home-content__placeholder">
          <a href="https://kungalvsposten.se/nyheter/rormossens-humle-ingar-i-lokalproducerad-ol/"
          target="_blank" rel="noopener noreferrer" className="caption-text">
            Källa: Kungälvs posten ”Rörmossens humle ingår i lokalproducerad öl” Foto: Bodil Hjelm
          </a>
        </div>
      </main>
    </React.Fragment>
  );
};

export default HomePage;